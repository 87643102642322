<template>
  <div class="md-layout md-gutter">
      <!-- <div class="md-layout-item md-size-75">
           <TextEditor
               class="h-64"
                :label="false"
            />
      </div> -->
      
      <div class="md-layout-item md-size-70">
          <MultipleSelectAnswer 
            v-model.trim="form.quiz_options" 
            :isCreateQuiz="isCreateQuiz"
            :items="items" />
      </div>

  </div>
</template>

<script>
//import TextEditor from "@/components/atom/form/TextEditor";
import MultipleSelectAnswer from "@/components/molecule/question/MultipleSelectAnswer";
//import CheckboxComponent from "@/components/atom/form/CheckboxComponent";
export default {
    components: {
        //TextEditor,
        MultipleSelectAnswer
    },
    props: {
        isCreateQuiz: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            items: [
                {id: 1, label: 'Answer Sample'},
                {id: 2, label: 'Answer Sample'},
                {id: 3, label: 'Answer Sample'},
                {id: 4, label: 'Answer Sample'},
            ],
            form: {
                quiz_options: [],
            },
        }
    }
}
</script>
