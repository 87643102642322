<template>
  <div class="md-layout md-gutter">
        <div class="md-layout-item md-size-45">
          <!-- <UploadComponent
            type="audio"
            icon-class="flaticon-volume"
            v-model.trim="form.audio_resource_path"
          /> -->
           <AudioUploader
              style="margin: 0 auto"
              v-model.trim="form.audio_resource_path"
          />
        </div>
      
        <div class="md-layout-item md-size-55 audio_quiz">
            <TextEditor
                :label="false"
                v-model.trim="form.title"
            />
        </div>
  </div>
</template>

<script>
import TextEditor from "@/components/atom/form/TextEditor";
//import UploadComponent from "@/components/molecule/question/UploadComponent";
import AudioUploader from "@/components/atom/media/AudioUploader";
export default {
    components: {
        TextEditor,
        AudioUploader,
       // UploadComponent
    },
    data() {
      return {
        form: {
          title: "",
          audio_resource_path: null
        },
      }
    },
}
</script>