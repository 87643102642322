<template>
  <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-40 img_quiz">
          <ImageUploader
          v-model.trim="form.image_resource_path"/>
      </div>
      
      <div class="md-layout-item md-size-60 audio_quiz">
            <TextEditor
                :label="false"
                v-model.trim="form.title"
            />
      </div>

  </div>
</template>

<script>
import TextEditor from "@/components/atom/form/TextEditor";
import ImageUploader from "@/components/atom/media/ImageUploader";
export default {
    components: {
        TextEditor,
        ImageUploader
    },
    data() {
      return {
        form: {
          title: "",
          image_resource_path: null
        },
      }
    },
}
</script>
