import AudioAndText from './AudioAndText';
import ImageAndText from './ImageAndText';
import VideoAndText from './VideoAndText';
import TextAndText from './TextAndText';

import MCQChooseSingleAnswer from './MCQChooseSingleAnswer'
import MCQChooseMultipleAnswer from './MCQChooseMultipleAnswer'
import TestAnswer from './TestAnswer'


export default {
    AudioAndText,
    ImageAndText,
    VideoAndText,
    TextAndText,

    TestAnswer,
    MCQChooseSingleAnswer,
    MCQChooseMultipleAnswer
}