<template>
  <div
    style="height: 400px"
    class="py-6 px-3 flex flex-col rounded outline-gray-400"
    :class="[switcher ? 'justify-between': 'justify-center']"
  >
    <div v-if="switcher" class="flex justify-center">
      <div class="border border-solid border-gray-400 py-2 px-4 pointer"
        :class="activeRecord" @click="record = true">
        Record
      </div>
      <div
        class="border border-solid border-gray-400 py-2 px-4 pointer"
        :class="activeBrowse"
        @click="onFileBrowse"
      >Browse</div>
      <!-- <input ref="file" type="file" class="hidden" @change="getFileContent"> -->
      <input ref="file" type="file" accept="video/*" class="hidden" @change="handleFileUpload( $event )"/>
    </div>
    <div class="flex justify-center" v-if="record">
      <div class="py-2 px-2 inline-flex align-center rounded bg-victoria text-white pointer">
        <VideoRecord @input="input"/>
      </div>
    </div>
    
    <div v-else>
      <div class="align-center w-full flex justify-center">
        <template>
          <video id="video-preview" controls v-show="file != ''" width="80%"/>
        </template>
        
      </div>
    </div>
    <div class="my-1">
      <label class="mx-2 text-left font-bold block">Or Add Youtube URL</label>
      <InputFieldComponent placeholder="paste youtube URL here"  @input="input"/>
    </div>
  </div>
</template>

<script>
import VideoRecord from "@/components/atom/media/video/VideoRecord";
import { InputFieldComponent } from "@/components";
import { putObject} from "@/store/upload";
const AWS = require('aws-sdk');
const spacesBaseUrl = new AWS.Endpoint(process.env.VUE_APP_DIGITALOCEAN_SPACES_ENDPOINT);
const urlMarge = spacesBaseUrl.protocol+'//'+process.env.VUE_APP_DIGITALOCEAN_SPACES_BUCKET+'.'+spacesBaseUrl.host
const baseUrl =urlMarge+'/'+process.env.VUE_APP_DIGITALOCEAN_SPACES_FOLDER+'/'

export default {
     components: {
        VideoRecord,
        InputFieldComponent
    },
  data() {
    return {
      record: true,
      fileName: "",
      path:'quiz'
      }
    },
    props: {
        iconClass: {
            type: String,
            default: () => ''
        },
        switcher: {
            type: Boolean,
            default: () => true
        },
        label: {
          type: String,
          default: () => 'Start Recording'
        },
        type: {
          type: String,
          default: () => ''
          // required: true,
          // validator: function(value) {
          //   return ["image", "audio", "video"].indexOf(value) !== -1;
          // },
        }
    },
    computed: {
      activeRecord() {
        return this.record ? 'bg-victoria text-white' : ''
      },
      activeBrowse() {
        return !this.record ? 'bg-victoria text-white' : ''
      },
      labelSplit() {
        return this.label.split(' ')
      }
    },
    methods: {
      getFileContent(e) {
        this.fileName = e.target.files[0].name;
        this.$emit('input', e.target.files[0]);
      },
      input(val){
        this.$emit('input',val)
      },
      handleFileUpload( event ){
            this.file = event.target.files[0];
            this.previewVideo();
            console.log(this.file)
            console.log("CustomData",this.file)
            this.file_url = URL.createObjectURL(this.file)
            //path assign here
            const paths = this.path +'/video/'+ this.file.name 
            this.file_path = paths.toLowerCase();
            this.filepath = this.path+'/video'
            putObject(this.file, this.filepath, "public-read")
            this.$emit('input',baseUrl+paths);
        },
      previewVideo(){
            let video = document.getElementById('video-preview');
            let reader = new FileReader();

            reader.readAsDataURL( this.file );
            reader.addEventListener('load', function(){
                video.src = reader.result;
            });
        },
      onFileBrowse() {
        this.record = false;
        this.$refs.file.click();
      },
      onPlacedComponent(type) {
        console.log(type, '==@@==');
      }
    }
};
</script>

<style>
#video-preview{
    height: 220px;
}
</style>
