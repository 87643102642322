const questionTypes = [
    {
        id: 1,
        name: 'VIDEO + TEXT',
        type: 'VideoAndText',
        component: 'VideoAndText'
    },
    {
        id: 2,
        name: 'AUDIO + TEXT',
        type: 'AudioAndText',
        component: 'AudioAndText'
    },
    {
        id: 3,
        name: 'IMAGE + TEXT',
        type: 'ImageAndText',
        component: 'ImageAndText'
    },
    
    {
        id: 4,
        name: 'TEXT + TEXT',
        type: 'TextAndText',
        component: 'TextAndText'
    }
];

const answerTypes = [
    {
        id: 11,
        type: 'MCQ-CHOOSE-MULTIPLE-ANSWER',
        name: 'MCQ CHOSE MULTIPLE ANSWER',
        component: 'MCQChooseMultipleAnswer',
    },
    {
        id: 12,
        type: 'MCQ-CHOOSE-SINGLE-ANSWER',
        name: 'MCQ CHOSE SINGLE ANSWER',
        component: 'MCQChooseSingleAnswer',
    },
    {
        id: 13,
        type: 'TEXT',
        name: 'TEXT',
        component: 'TestAnswer',
    }
];

export default {
    questionTypes,
    answerTypes
}